import React from "react"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import LazyLoad from "react-lazyload"
const regex = /(\/upload\/)/
const parseUrl = (url, width) => {
  return url.replace(regex, `/upload/w_${width},c_scale/`)
}
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
  },
  stepNo: {
    backgroundColor: "#d6d6d6",
    padding: "3px 11px",
    borderRadius: "50%",
    marginRight: 5,
  },
}))
const Step = ({ step, width }) => {
  const { text, number, images } = step
  const classes = useStyles()
  return (
    <div style={{ marginBottom: 8, marginTop: 8 }}>
      <div className={classes.root}>
        <Typography className={classes.stepNo}>{number}</Typography>
        <Typography style={{ marginBottom: 8 }}>{text}</Typography>
      </div>
      {/* { isEmpty(step.images) ? null : <Carousel images={step.images}/>} */}
      {images.map((image, index) => (
        <LazyLoad once offset={100} height={200} key={index}>
          <img
            src={parseUrl(image, width)}
            alt={text}
            style={{ width, height: "auto", marginBottom: 3 }}
          />
        </LazyLoad>
      ))}
    </div>
  )
}

export default Step
