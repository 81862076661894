import React from "react"
import { parse } from "query-string"
import Document from "../containers/Document"

const view = ({ location: { search } }) => {
  const { id } = parse(search)
  return <Document docId={id} />
}

export default view
