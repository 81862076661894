import React, { useState, useEffect } from 'react'
import Step from './Step'
import { makeStyles } from '@material-ui/core/styles'
import {
  Typography,
  Chip,
  Box,
  Grid,
  Button,
  Link,
  Divider,
} from '@material-ui/core'
import LocalOfferIcon from '@material-ui/icons/LocalOfferOutlined'
import LocalDiningIcon from '@material-ui/icons/LocalDining'
import AccessTimeIcon from '@material-ui/icons/AccessTimeOutlined'
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline'
import ClearAllIcon from '@material-ui/icons/ClearAll'
import { useLazyQuery } from '@apollo/react-hooks'
import QUERY from '../../query'
import fb from '../../utils/facebook'
import YouTube from 'react-youtube'
import { isEmpty } from 'lodash'
let maxWidth = 720
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    paddingTop: 20,
    paddingBottom: 20,
    minWidth: '30%',
    maxWidth: '100%',
  },
  tags: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignContent: 'baseline',
    flexWrap: 'wrap',
    lineHeight: 30,
  },
  info: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    marginTop: 10,
    flexWrap: 'wrap',
  },
  infoItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
  },
  infoText: {
    wihteSpace: 'no-wrap',
  },
  subInfo: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
}))
const Document = ({ docId }) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      fb(FB => timeout && FB.XFBML.parse())
    }, 2000)
    return () => {
      clearTimeout(timeout)
      // typeof window !== 'undefined' && delete timeout;
    }
  }, [])
  const [getDocument, { data }] = useLazyQuery(QUERY.DOCUMENT)
  useEffect(() => {
    getDocument({
      variables: { docId },
    })
  }, [docId, getDocument])
  const [width, setWidth] = useState(maxWidth)
  const viewportWidth =
    typeof window !== 'undefined' &&
    Math.max(window.document.documentElement.clientWidth, window.innerWidth)
  useEffect(() => {
    if (maxWidth >= viewportWidth) {
      setWidth(viewportWidth * 0.95)
    }
  }, [viewportWidth])
  const classes = useStyles()
  const Method = ({ method }) => (
    <div>
      {method.map((step, index) => (
        <Step step={step} key={index} width={parseInt(width)} />
      ))}
    </div>
  )
  const Tags = ({ tags }) => (
    <>
      <LocalOfferIcon style={{ width: 30, height: 30 }} />
      {tags.map(tag => (
        <Chip
          key={tag}
          color="secondary"
          label={<Typography style={{ color: '#ffffff' }}>{tag}</Typography>}
          style={{ marginLeft: 3, marginBottom: 3 }}
        />
      ))}
    </>
  )
  if (data) {
    const {
      document: {
        dataType,
        name,
        cover,
        intro,
        method,
        ingredients,
        tags,
        taste,
        level,
        totalTime,
        serving,
        author: { domain, name: authorName, source },
        relatedYoutubeVideoIds,
        redirectURL,
      },
    } = data
    if (redirectURL) {
      window.open(redirectURL)
    } else {
      return (
        <div className={classes.root} style={{ width }}>
          <Typography variant="h4" paragraph={true} component="div">
            <Box fontWeight="fontWeightBold">{name}</Box>
          </Typography>
          <Typography paragraph={true}>{intro}</Typography>
          <div className={classes.tags}>
            <Tags tags={tags} />
          </div>
          <img
            src={cover}
            alt="cover"
            style={{ width: width, height: 'auto', marginBottom: 3 }}
          />
          <div
            style={{
              display: 'flex',
              marginTop: '2px',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <div
              className="fb-like"
              data-href={window.location.href}
              data-width=""
              data-layout="button"
              data-action="recommend"
              data-size="small"
              data-show-faces="true"
              data-share="true"
            />
            <div
              className="fb-save"
              data-uri={window.location.href}
              data-size="small"
            />
          </div>
          {dataType !== 'recipe' ? null : (
            <>
              <Grid
                spacing={1}
                container
                alignContent="space-around"
                wrap="wrap"
                style={{ marginTop: '8px' }}
              >
                <Grid
                  item
                  alignContent="space-around"
                  container
                  xs={12}
                  sm={6}
                  wrap="nowrap"
                >
                  <Grid
                    item
                    xs={6}
                    alignItems="center"
                    alignContent="center"
                    container
                    wrap="nowrap"
                  >
                    <AccessTimeIcon />
                    <Typography className={classes.infoText}>
                      {totalTime}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    alignItems="center"
                    alignContent="center"
                    container
                    wrap="nowrap"
                  >
                    <PeopleOutlineIcon />
                    <Typography className={classes.infoText}>
                      {serving}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  alignContent="space-around"
                  container
                  xs={12}
                  sm={6}
                  wrap="nowrap"
                >
                  <Grid
                    item
                    xs={6}
                    alignItems="center"
                    alignContent="center"
                    container
                    wrap="nowrap"
                  >
                    <LocalDiningIcon />
                    <Typography className={classes.infoText}>
                      {taste}
                    </Typography>
                    {/* "Not Spectified"} */}
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    alignItems="center"
                    alignContent="center"
                    container
                    wrap="nowrap"
                  >
                    <ClearAllIcon />
                    <Typography className={classes.infoText}>
                      {level}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Typography style={{ marginTop: '8px' }}>
                Nguyên liệu: {ingredients.map(({ name }) => name).join(', ')}
              </Typography>
              {domain === 'cookpad.com' || domain === 'recipe.rakuten.co.jp' ? (
                <Button
                  color="primary"
                  variant="contained"
                  style={{
                    marginRight: '20%',
                    marginLeft: '20%',
                    marginTop: 5,
                    marginBottom: 5,
                  }}
                  onClick={() => window.open(source)}
                >
                  <Typography
                    variant="button"
                    color="textPrimary"
                    component="h2"
                  >
                    HOW TO COOK
                  </Typography>
                </Button>
              ) : (
                <Method method={method} />
              )}
            </>
          )}
          <div style={{ display: 'flex', width: '100%' }}>
            <div style={{ flexGrow: 1 }} />
            {authorName && (
              <Typography>
                <Link
                  underline="none"
                  onClick={() => {
                    if (source) {
                      window.open(source)
                    }
                  }}
                  color="secondary"
                  component="button"
                >
                  {`${authorName} - ${domain}`}
                </Link>
              </Typography>
            )}
          </div>
          {!isEmpty(relatedYoutubeVideoIds) && (
            <>
              <Divider />
              <Typography variant="h6">You may like</Typography>
              <Grid container={true} style={{ paddingTop: 10 }}>
                {relatedYoutubeVideoIds.map(id => (
                  <Grid
                    key={id}
                    item={true}
                    lg={6}
                    md={6}
                    sm={6}
                    xl={6}
                    xs={12}
                  >
                    <YouTube
                      videoId={id}
                      opts={{ height: '200', width: '340' }}
                    />
                  </Grid>
                ))}
              </Grid>
            </>
          )}
          {/* </div> */}
          <div
            className="fb-comments"
            data-href={window.location.href}
            data-width={width}
            data-numposts="5"
          ></div>
        </div>
      )
    }
  }
  return null
}

export default Document
